/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: Timothy Ahene (https://sketchfab.com/timothyahene)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/crt-tv-9ba4baa106e64319a0b540cf0af5aa9e
Title: CRT TV
*/


import React, { useRef } from "react";
import { Html, useGLTF } from "@react-three/drei";
import Screen from "./Screen";
import { Route } from "wouter";
import Projects from "./Projects";
import About from "./About";
import Contact from "./Contact";

export default function CRTv(props) {
  const { nodes, materials } = useGLTF("/crt_tv.glb");
  const isMobile = window.innerWidth <= 600



  return (
    <group scale={ isMobile? .999 : .995} position={[0,-0.2,41.05]} {...props} dispose={null}>
      <group
        position={[0, 0, -36.571]}
        rotation={[-Math.PI / 2, 0, -Math.PI]}
        scale={0.01}
      >
        <group rotation={[Math.PI / 2, Math.PI, 0]}>
          <group position={[0, 26.836, 0]}>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.defaultMaterial.geometry}
              material={materials.TVback}
              position={[0, -0.042, 0]}
              scale={[0.99, 0.99, 1]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.defaultMaterial_1.geometry}
              material={materials.TVfront}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.defaultMaterial_2.geometry}
              material={materials.TVfront}
            />
            <mesh
              name="screen"
              castShadow
              receiveShadow
              geometry={nodes.defaultMaterial_3.geometry}
              material={materials.TVScreen}
              position={[0, 0, -166.617]}
              scale={398.216}
            >
              {/* <Screen></Screen> */}
              <Route path="/" component={Screen}></Route>
              <Route path="/projects" component={Projects}></Route>
              <Route path="/about" component={About}></Route>
              <Route path="/contact" component={Contact}></Route>
            </mesh>
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.defaultMaterial_4.geometry}
              material={materials.TVfront}
              position={[3.169, -20.046, -18.301]}
              rotation={[0, 0, -0.596]}
              scale={[0.378, 0.378, 0.221]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.defaultMaterial_5.geometry}
              material={materials.TVfront}
              position={[0, -26.836, 0]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.defaultMaterial_6.geometry}
              material={materials.TVfront}
              position={[11.057, -26.295, -18.646]}
              rotation={[-Math.PI, 0, -Math.PI]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.defaultMaterial_7.geometry}
              material={materials.TVfront}
              position={[26.908, -17.616, -17.225]}
              rotation={[-Math.PI / 2, Math.PI / 2, 0]}
              scale={[0.173, 0.159, 0.173]}
            />
            <mesh
              castShadow
              receiveShadow
              geometry={nodes.defaultMaterial_8.geometry}
              material={materials.TVfront}
              position={[0.003, -19.835, 0.852]}
              scale={0.11}
            />
          </group>
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/crt_tv.glb");